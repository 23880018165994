import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { firebase_app, firestore_db } from '../../api/firebase';
import { collection, doc, query, where, getDoc } from "firebase/firestore";
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Form, Button, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Routes } from "../../routes";

const Table = ({
  columns,
  data,
  serverData,
  fetchData,
  loading,
  pageCount: controlledPageCount
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      serverData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount
    },
    usePagination
  )

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, serverData })
  }, [fetchData, pageIndex, pageSize]);

  let index = 0;

  const renderCell = (cell) => {
    const value = cell.value;
    const header = cell.column.Header;

    if (header === '#') {
      index = index + 1;
      return index;
    }

    if (typeof value === 'boolean') {
      if (value) {
        return 'Y';
      } else {
        return 'N';
      }
    }

    return cell.render('Cell');
  }

  return (
    <>
      <table className="table-centered table-nowrap rounded mb-0 table" {...getTableProps()}>
        <thead className="thead-light">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                if (column.Header !== 'Header') {
                  return (<th className="border-0" {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>);
                  }
                }
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{renderCell(cell)}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination" style={{justifyContent: 'end', padding: '7px 0'}}>
        <span style={{ fontSize: 14, paddingTop: 2 }}>
          Rows per page{' '}
          {pageIndex + 1} of {pageOptions.length}
        </span>
        <span>
          <Form.Select style={{ width: 50, fontSize: 14, padding: 2, marginLeft: 15 }}
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}>
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>
        </span>
        <Button style={{ width: 28, padding: 2, marginLeft: 15 }}
          size="sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}>
            {'<'}
        </Button>{' '}
        <Button style={{ width: 28, padding: 2, marginLeft: 5 }}
          size="sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}>
            {'>'}
        </Button>
      </div>
    </>
  )
};

export const ServicesTable = (props) => {
  const { update } = props;
  const [tableData, setTableData] = useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const currentUser = useSelector(state => state.signIn);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Header',
        columns: [
          {
            Header: '#',
            accessor: '#',
          },
          {
            Header: 'Products & Services',
            accessor: 'productsServices',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'Installed',
            accessor: 'installed',
          },
          {
            Header: 'Version',
            accessor: 'version',
          },
          {
            Header: 'Upgrade',
            accessor: 'upgrade',
          },
        ],
      },
    ],
    []
  );
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex, serverData }) => {
    if (serverData && serverData.length > 0) {

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Set the loading state
      setLoading(true)

      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setData(serverData.slice(startRow, endRow))

        setPageCount(Math.ceil(serverData.length / pageSize))

        setLoading(false)
      }
    }
  }, [])

  const getServices = async () => {
    const defaultDomain = currentUser.data.user.websiteLease.split(',')[0];
    const selectedWebsiteLease = currentUser.data.user.selectedWebsiteLease || defaultDomain;
    const docRef = doc(firestore_db, "domains", selectedWebsiteLease);
    const docSnap = await getDoc(docRef);
    return docSnap;
  }

  useEffect(() => {
    let isSubscribed = true;
    if (currentUser.data.user &&
      currentUser.data.user.websiteLease) {
      getServices().then((doc) => {
        if (Object.keys(doc.data()).length > 0) {
          const newData = update;
          let data = doc.data().integrations;
          if (newData) {
            data = Object.assign(data, newData);
          }
          setTableData(data);
          const pageSize = 10;
          const pageIndex = 0;
          const serverData = data;
          fetchData({ pageSize, pageIndex, serverData });
        } else {
          setTableData([]);
          setData([]);
        }
      });
    }
    return () => isSubscribed = false;
  }, [currentUser, update]);

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <div className="table-responsive">
          <Table
            columns={columns}
            data={data}
            serverData={tableData}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
          />
        </div>
      </Card.Body>
    </Card>
  );
};
