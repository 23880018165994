import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { signInChangeState } from '../store/auth/SignIn';
import Preloader from '../components/Preloader';
import { auth } from '../api/firebase';
import { Routes } from "../routes";
import { AddService } from '../components/AddService';
import Dashboard from "./dashboard/Dashboard";
import Services from "./services/Services";
import Signin from "./auth/SignIn/SignIn";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { history } from '../history';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.signIn);

  if(currentUser.data.user && currentUser.data.user.role !== 'admin' && rest.path === '/dashboard') {
    history.push('/services');
  }

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    if (Object.keys(currentUser.data).length === 0) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const { email } = user;
          dispatch(signInChangeState({ email }));
        }
      });
    }
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  const handleModal = () => {
    setOpen(!open);
  }

  const handleData = (newData) => {
    setData(newData);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar handleModal={handleModal} />
          <AddService handleData={handleData} handleModal={handleModal} open={open} />
          <Component update={data} {...props} />
        </main>
      </>
    )}
    />
  );
};

export default () => {

  return (
    <Switch>
      <RouteWithLoader exact path={Routes.SignIn.path} component={Signin} />
      <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />
      <RouteWithSidebar exact path={Routes.Services.path} component={Services} />
    </Switch>)
};
