import { all, takeLatest } from 'redux-saga/effects';
import {
	signIn,
	signInChangeState,
	signInChangeWebsiteLease,
	worker_signIn,
	worker_signInChangeState,
	worker_signInChangeWebsiteLease
} from './SignIn';

export function* auth() {
    yield all([
        takeLatest(signIn, worker_signIn),
        takeLatest(signInChangeState, worker_signInChangeState),
        takeLatest(signInChangeWebsiteLease, worker_signInChangeWebsiteLease)
    ]);
}