export const setStateOnRequest = ({ defaultDataState, draft }) => {
    draft.data = defaultDataState;
    draft.loading = true;
    draft.error = null;
};

export const setStateOnChangeState = ({ draft, action }) => {
    draft.data = action.payload;
    draft.loading = false;
    draft.error = null;
};

export const setStateOnSuccess = ({ draft, action }) => {
    draft.data = action.payload;
    draft.loading = false;
    draft.error = null;
};

export const setStateOnError = ({
    defaultDataState,
    draft,
    action,
}) => {
    draft.data = defaultDataState;
    draft.loading = false;
    draft.error = action.payload;
};

export const getInitialState = (dataDefaultState: {}) => ({
    data: dataDefaultState,
    loading: false,
    error: null,
});

export const allInOne = ({
    request,
    changeState,
    success,
    error,
    defaultDataState,
    draft,
    action,
}) => {
    switch (action.type) {
        case request:
            setStateOnRequest({ defaultDataState, draft });
            break;
        case changeState:
            setStateOnChangeState({ draft, action });
            break; 
        case success:
            setStateOnSuccess({ draft, action });
            break;
        case error:
            setStateOnError({ defaultDataState, draft, action });
            break;
        default:
            break;
    }
};