import { signInWithEmailAndPassword } from "firebase/auth";
import { ref, get, query, orderByChild, equalTo } from "firebase/database";
import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { signInError, signInSuccess } from './actions';
import { auth, firebase_db } from '../../../api/firebase';
import { history } from '../../../history';

const getUserProfile = (email) => {
  const users = ref(firebase_db, '/users');
  return new Promise((resolve, reject) => {
    return get(query(users, orderByChild('email'), equalTo(email))).then((snapshot) => {
      if (snapshot.exists()) {
        return resolve(snapshot.val());
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  });
}

export function* worker_signInChangeWebsiteLease(action) {
  yield put(signInSuccess(action.payload));
}

export function* worker_signInChangeState(action) {
  let infoToastID;
  try {
    infoToastID = toast.info('Loading');

    const { email } = action.payload;
    const results = yield getUserProfile(email);
    const newModel = {
      user: {
        email
      }
    };

    for (const key in results) {
      if (results[key].email === email) {
        newModel.user.role = results[key].role || 'user';
        newModel.user.websiteLease = results[key].websiteLease;
      }
    }

    toast.dismiss(infoToastID);
    toast.success('User logged in');

    yield put(signInSuccess(newModel));

  } catch (error) {
    toast.dismiss(infoToastID);
    toast.error(error.message);
    yield put(signInError(error));
  }
}

export function* worker_signIn(action) {
  let infoToastID;
  try {
    infoToastID = toast.info('Loading');

    const { email, password } = action.payload;
    const userCredentials = yield signInWithEmailAndPassword(auth, email, password);
    const results = yield getUserProfile(email);
    const newModel = {
      user: {
        email
      }
    };

    for (const key in results) {
      if (results[key].email === email) {
        newModel.user.role = results[key].role || 'user';
        newModel.user.websiteLease = results[key].websiteLease;
      }
    }

    toast.dismiss(infoToastID);
    toast.success('User logged in');

    yield put(signInSuccess(newModel));

    // redirect
    history.push('/services');

  } catch (error) {
    toast.dismiss(infoToastID);
    toast.error(error.message);
    yield put(signInError(error));
  }
}