import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { doc, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { firestore_db } from '../api/firebase';
import { toast } from 'react-toastify';
import { Modal, Form, Button } from '@themesberg/react-bootstrap';

const AddService = (props) => {
  const { open, handleModal, handleData } = props;
  const [domains, setDomains] = useState([]);
  const [formData, setFormData] = useState({
    service: '',
    productsServices: '',
    description: '',
    version: '',
    installed: false,
    upgrade: false
  });
  const currentUser = useSelector(state => state.signIn);

  const handleForm = (e) => {
    const {name, value} = e.target;
    const newModel = {...formData};
    newModel[name] = value === 'on' ? true : value;
    setFormData(newModel);
  }

  const handleSubmit = async () => {
    const selectedWebsiteLease = currentUser.data.user.selectedWebsiteLease || domains[0];
    const domainsRef = doc(firestore_db, "domains", selectedWebsiteLease);
    await updateDoc(domainsRef, {
      integrations: arrayUnion(formData)
    });
    toast.success('Integration added');
    handleData(formData);
    document.getElementById("IntegrationForm").reset();
    setFormData({
      service: '',
      productsServices: '',
      description: '',
      version: '',
      installed: false,
      upgrade: false
    });
  }

  useEffect(() => {
    let isSubscribed = true;
    if (currentUser.data.user &&
      currentUser.data.user.websiteLease) {
      setDomains(currentUser.data.user.websiteLease.split(','));
    }
    return () => isSubscribed = false;
  }, [currentUser]);

  return (<Modal show={open} dialogClassName="modal-50w" onHide={handleModal}>
    <Modal.Header closeButton>
      <Modal.Title>Integration</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form id="IntegrationForm">
        <Form.Group className="mb-3">
          <Form.Label>Service</Form.Label>
          <Form.Select name="service" onChange={handleForm}>
            <option>Select service</option>
            <option>Affordable Domains</option>
            <option>AWS</option>
            <option>Blutui</option>
            <option>DNSimple</option>
            <option>Godaddy</option>
            <option>Google</option>
            <option>JavaScript</option>
            <option>WIX</option>
            <option>Wagtail</option>
            <option>Web templates</option>
            <option>WordPress</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Products & services</Form.Label>
          <Form.Select name="productsServices" onChange={handleForm}>
            <option>Select products & services</option>
            <option>AWS API Gateway</option>
            <option>AWS Certificate Manager</option>
            <option>AWS CloudFront</option>
            <option>AWS Lambda</option>
            <option>AWS S3</option>
            <option>AWS Lightsail</option>
            <option>AWS Gateway</option>
            <option>Blutui</option>
            <option>CSS, HTML & JavaScript</option>
            <option>Domain management</option>
            <option>Google Analytics</option>
            <option>Google Search Console</option>
            <option>Microsoft 365 Business Professional</option>
            <option>Microsoft 365 Email Plus</option>
            <option>React</option>
            <option>Wagtail</option>
            <option>WIX: Unlimied Website Plan</option>
            <option>WordPress</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control name="description" onChange={handleForm} type="text" placeholder="Content Management Service" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Version</Form.Label>
          <Form.Control name="version" onChange={handleForm} type="text" placeholder="1.0.0" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check 
            type="checkbox"
            label="Installed"
            name="installed"
            onChange={handleForm}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check 
            type="checkbox"
            label="Upgrade"
            name="upgrade"
            onChange={handleForm}
          />
        </Form.Group>
      </form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleModal}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleSubmit}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>);
}

export { AddService };