import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';
import { signInChangeWebsiteLease } from '../store/auth/SignIn';
import { useLocation } from 'react-router-dom';
import { Container, Nav, FormControl, Button, Image, Navbar, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const { handleModal } = props;
  const [domains, setDomains] = useState([]);
  const currentUser = useSelector(state => state.signIn);
  const dispatch = useDispatch();
  const location = useLocation();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span style={{
        fontSize: '12px',
        margin: '9px 0 0 6px',
        display: 'inline-block'
      }}>&#x25bc;</span>
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  const handleDropdown = (e) => {
    const newWebsiteLease = e.target.innerHTML.replace(/\s/g, '');
    const newModel = {
      user: {
        email: currentUser.data.user.email,
        role: currentUser.data.user.role,
        websiteLease: currentUser.data.user.websiteLease,
        selectedWebsiteLease: newWebsiteLease
      }
    }
    dispatch(signInChangeWebsiteLease(newModel));
  }

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      location.href = '/';
    }).catch((error) => {
      console.log(error);
    });
  }

  const renderSelection = () => {
    const headerStyle = currentUser.data.user && currentUser.data.user.role === 'admin' ? 
      {float: 'left', margin: '0 25px 16px 0'} : {};

    const selectedWebsiteLease = currentUser.data.user.selectedWebsiteLease || domains[0];

    if (domains.length < 2) {
      return <h4 style={headerStyle}>{selectedWebsiteLease}</h4>
    }

    return <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <h4 style={{float: 'left'}}>{selectedWebsiteLease}</h4>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {domains.map((item, index) => {
            return <Dropdown.Item eventKey={index} key={index} onClick={handleDropdown}>{item}</Dropdown.Item>;
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  }

  useEffect(() => {
    let isSubscribed = true;
    if (currentUser.data.user &&
      currentUser.data.user.websiteLease) {
      setDomains(currentUser.data.user.websiteLease.split(','));
    }
    return () => isSubscribed = false;
  }, [currentUser]);

  return (
    <>
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
              <div>
                {location.pathname === '/dashboard' &&
                  <div>
                    <h4>Overview</h4>
                  </div>
                }
                {location.pathname === '/services' &&
                  <div>
                    {currentUser.data.user && currentUser.data.user.role !== 'admin' &&
                      (<span>{renderSelection()}</span>)
                    }
                    {currentUser.data.user && currentUser.data.user.role === 'admin' &&
                      <>
                        <span style={{
                          float: 'left',
                          marginRight: '14px'
                        }}>{renderSelection()}</span>
                        <Button variant="primary" size="sm" onClick={handleModal}>Add service</Button>
                      </>
                    }
                  </div>
                }
              </div>
            <Nav className="align-items-center" style={{cursor: 'pointer'}}>
              <a href="#" onClick={handleSignOut}>
                <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
              </a>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
