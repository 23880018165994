import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCk90K4Nl2LpC2WHfOBO69OTodLwD_CX30",
  authDomain: "agilitycycle.firebaseapp.com",
  databaseURL: "https://agilitycycle-default-rtdb.firebaseio.com",
  projectId: "agilitycycle",
  storageBucket: "agilitycycle.appspot.com",
  messagingSenderId: "692918794685",
  appId: "1:692918794685:web:72733340a77e957b8c3ad4",
  measurementId: "G-2LZ6GJ88N6"
};

export const firebase_app = initializeApp(firebaseConfig);
export const firestore_db = getFirestore(firebase_app);
export const firebase_db = getDatabase(firebase_app);
export const auth = getAuth(firebase_app);
