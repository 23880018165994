import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { firebase_app, firestore_db } from '../../api/firebase';
import { collection, doc, query, where, getDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { CounterWidget } from "./ServicesWidgets";
import { ServicesTable } from "./ServicesTable";

export default (props) => {
  const [widgetData, setWidgetData] = useState({
  	backlinks: 0,
  	uptime: 100
  });
  const currentUser = useSelector(state => state.signIn);

  const getData = async () => {
    const defaultDomain = currentUser.data.user.websiteLease.split(',')[0];
    const selectedWebsiteLease = currentUser.data.user.selectedWebsiteLease || defaultDomain;
    const docRef = doc(firestore_db, "domains", selectedWebsiteLease);
    const docSnap = await getDoc(docRef);
    return docSnap;
  }

  useEffect(() => {
    let isSubscribed = true;
    if (currentUser.data.user &&
      currentUser.data.user.websiteLease) {
      getData().then((doc) => {
        if (Object.keys(doc.data()).length > 0) {
          let backlinks = doc.data().backlinks || 0;
          let uptime = doc.data().uptime || 100;
          setWidgetData({
            backlinks,
            uptime
          });
        }
      });
    }
    return () => isSubscribed = false;
  }, [currentUser]);

  return (
  	<>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={6} className="mb-4">
			  	<CounterWidget
		        category="Backlinks"
		        title={widgetData.backlinks}
		        period="Feb 1 - Apr 1"
		        percentage={18.2}
		        icon={faChartLine}
		        iconColor="shape-secondary"
		      />
	      </Col>
	      <Col xs={12} sm={6} xl={6} className="mb-4">
			  	<CounterWidget
		        category="Uptime"
		        title={`${widgetData.uptime}%`}
		        period="Feb 1 - Apr 1"
		        percentage={18.2}
		        icon={faChartLine}
		        iconColor="shape-secondary"
		      />
		    </Col>
	    </Row>
	    <ServicesTable update={props.update} />
    </>
  );
};
